<template>
  <view-item title="员工业绩报表">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active" @change="tabs.change">
            <en-tab-pane label="服务顾问" name="statistics">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <form-query :method="statistics.table.daily" :model="statistics.form.data">
                    <en-form-item label="收款状态">
                      <select-maintain
                        v-model="statistics.form.data.settlementProgressCode"
                        :ajax="{
                          action: 'GET /enocloud/common/lookup/:lookupType',
                          params: (params) => {
                            params.paths = ['STLMTPRG']
                          }
                        }"
                        :props="{ label: 'message', value: 'code' }"
                        clearable
                      ></select-maintain>
                    </en-form-item>
                    <en-form-item label="结算日期">
                      <en-date-picker
                        :disabled="statistics.form.data.status === 'RP'"
                        v-model:start="statistics.form.data.startDate"
                        v-model:end="statistics.form.data.endDate"
                        type="daterange"
                        value-format="YYYY-MM-DD"
                      ></en-date-picker>
                    </en-form-item>
                    <en-form-item label="门店" prop="branchIds">
                      <select-maintain
                        v-model="statistics.form.data.branchIds"
                        :ajax="{
                          action: 'GET /enocloud/common/branch',
                          params: (params) => {
                            params.payload = { pagingEnabled: false }
                          }
                        }"
                        :props="{ label: 'shortName', value: 'id' }"
                        clearable
                        multiple
                      ></select-maintain>
                    </en-form-item>
                  </form-query>
                  <flex-box>
                    <template #default="{ height }">
                      <table-dynamic
                        code="SVCADVSTM"
                        :height="height"
                        :data="statistics.table.data"
                        :loading="statistics.table.loading"
                        :method="statistics.table[statistics.form.data.mode]"
                        show-summary
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                        <template #COUNT="{ row }: { row: EnocloudStatementDefinitions['AdvisorStatisticsDto']}">
                <en-button type="primary" text @click="operation.name.click(row)">{{ row.count }}</en-button>
              </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
            <en-tab-pane label="班组技师" name="customer">
              <div :style="{ height: `${height - 55}px` }">
                <div class="h-full">
                  <form-query :method="customer.table[customer.mode]" :model="customer.form.data">
                    <en-form-item label="状态">
                  <en-checkbox v-model="customer.mode" true-label="daily" false-label="daily" @change="customer.type.change">按班组统计</en-checkbox>
                  <en-checkbox v-model="customer.mode" true-label="monthly" false-label="monthly" @change="customer.type.change">按技师统计</en-checkbox>
                </en-form-item>

                    <en-form-item label="结算日期">
                      <en-date-picker
                        :disabled="customer.form.data.status === 'RP'"
                        v-model:start="customer.form.data.settlementStartDate"
                        v-model:end="customer.form.data.settlementEndDate"
                        type="daterange"
                        value-format="YYYY-MM-DD"
                      ></en-date-picker>
                    </en-form-item>
                    <en-form-item label="结清日期">
                      <en-date-picker
                        :disabled="customer.form.data.status === 'RP'"
                        v-model:start="customer.form.data.settleStartDate"
                        v-model:end="customer.form.data.settleEndDate"
                        type="daterange"
                        value-format="YYYY-MM-DD"
                      ></en-date-picker>
                    </en-form-item>
                    <en-form-item label="门店" prop="branchIds">
                      <select-maintain
                        v-model="customer.form.data.branchIds"
                        :ajax="{
                          action: 'GET /enocloud/common/branch',
                          params: (params) => {
                            params.payload = { pagingEnabled: false }
                          }
                        }"
                        :props="{ label: 'shortName', value: 'id' }"
                        clearable
                        multiple
                      ></select-maintain>
                    </en-form-item>
                  </form-query>

                  <flex-box v-if="customer.mode === 'daily'">
                    <template #default="{ height }">
                      <table-dynamic
                        code="WTSTM"
                        :height="height"
                        :data="customer.table.data"
                        :loading="customer.table.loading"
                        :paging="customer.table.paging"
                        :summary="customer.table.summary.data"
                        :method="customer.table.daily"
                        pagination
                        show-summary
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>

                  <flex-box v-if="customer.mode === 'monthly'">
                    <template #default="{ height }">
                      <table-dynamic
                        code="WTASTM"
                        :height="height"
                        :data="customer.table.data"
                        :loading="customer.table.loading"
                        :paging="customer.table.paging"
                        show-summary
                        :summary="customer.table.summary.data"
                        :method="customer.table.monthly"
                        pagination
                   
                      >
                        <template #INDEX="{ $index }">
                          {{ $index + 1 }}
                        </template>
                      </table-dynamic>
                    </template>
                  </flex-box>
                </div>
              </div>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">

import { omit, pick } from 'lodash-es'

export default factory({
  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              service: {
                action: 'GET /enocloud/statement/export/advisor',
                params(params) {
                  params.payload = omit(this.statistics.form.data, 'mode')
                }
              },
              teamGroup: {
                action: 'GET /enocloud/statement/export/working/team',
                params(params) {
                  params.payload = omit(this.statistics.form.data, 'mode')
                }
              },
              teacher: {
                action: 'GET /enocloud/statement/export/working/team/assignee',
                params(params) {
                  params.payload = omit(this.statistics.form.data, 'mode')
                }
              }
            },
            click() {
              switch (this.tabs.active) {
                case 'statistics':
                  this.operation.export.service()
                  this.store.openDownload()
                  break
                case 'customer':
                  if (this.customer.form.data.mode === 'daily') this.operation.export.teamGroup()
                  if (this.customer.form.data.mode === 'monthly') this.operation.export.teacher()
                  this.store.openDownload()
                  break
              }
            }
          },
          name:{
            click(row : EnocloudStatementDefinitions['AdvisorStatisticsDto']){
              this.router.push('/query/workorder', (vm) => {
              vm.tabs.active = 'amount'
              vm.amount.query.data.advisorId= row.advisorUserId
              vm.amount.query.data.status = 'PD'
              vm.amount.query.data.settlementProgress = 'A'
              vm.amount.get()
           
            })
            }
          }
        }
      },
      tabs: {
        active: 'statistics',
        change() {
          switch (this.tabs.active) {
            case 'statistics':
              this.statistics.table.daily()
              break
            case 'customer':
              this.customer.type.change()
              break
          }
        }
      },
      statistics: {
        children: {
          form: {
            is: 'form',
            data: {
              status: 'PD',
              branchIds: [],
              settlementProgressCode:'A',
              endDate: '',
              startDate: '',
              advisorUserId:''
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/advisor',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.statistics.form.data, 'endDate', 'startDate', 'branchIds', 'settlementProgressCode')
                }
              }
            }
          }
        }
      },
      customer: {
        mode: 'daily' as 'daily' | 'monthly',
        children: {
          form: {

            data: {
              serviceCategoryName: '',
              settlementProgress: '',
              status: 'PD',
              customerCategoryId: '',
              branchIds: [],
              vehicleSpec: '',
              settlementStartDate: '',
              settlementEndDate: '',
              settleStartDate: '',
              settleEndDate: '',
              enterStartDate: '',
              enterEndDate: '',
              vehicleSpecArr: [],
     
       
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/working/team',
                summary: 'GET /enocloud/statement/working/team/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.customer.form.data, 'mode', 'settlementStartDate', 'settlementEndDate', 'branchIds', 'status','customerCategoryId')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/working/team/assignee',
                summary: 'GET /enocloud/statement/working/team/assignee/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['monthly']
                  params.payload = pick(
                    this.customer.form.data,
                    'mode', 
                    'settlementStartDate',
                    'settlementEndDate',
                    'branchIds',
                    'status',
                    'serviceCategoryName',
                    'customerCategoryId'
                  )
                }
              }
            },
          
          },
          type: {
            change() {
              switch (this.customer.mode) {
                case 'daily':
                  this.customer.table.daily()
                  break
                case 'monthly':
                this.customer.table.monthly()
                  break
             
              }
            }
          }
        }
      }
    }
  },

 async mounted() {
   this.statistics.table.daily()
   this.customer.table.daily()
   this.customer.table.monthly()
  }
})
</script>
